import React, {useEffect} from 'react';
import styled from 'styled-components';
import {NotificationChip} from '../components/UIkit';
import {useDispatch, useSelector} from "react-redux";
import {fetchNotifications} from "../reducks/Notification/operations";
import {getNotifications} from "../reducks/Notification/selectors";

const media = {
  sp: '@media(max-width: 650px)'
}

const Container = styled.div`
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  margin: 0 auto;
  max-width: 1080px;
`;

const Main = styled.main`
  width: calc(100% - 300px);
  p {
    padding:0;
  }
  ${media.sp} {
      width: 100%;
      padding: 0 1em;
   }
`;

const Chips = styled.main`
  padding-top: 1em;
`;

const Notification = () => {
    const dispatch = useDispatch()
    const selector = useSelector(state => state);
    const notifications = getNotifications(selector)
    console.log(notifications)
 
    useEffect(() => {
        dispatch(fetchNotifications());
    },[])

    return (
      <div className="common__item">
      <div className="common__center">
      <Container>
     <Main>
     <h2>通知</h2>
        <Chips>
          {notifications.length > 0 && (
            notifications.map(notification => (
              <NotificationChip
               key={notification.id} id={notification.id} text={notification.text} subject={notification.subject}
              />
            ))
          )}
        </Chips>
      <Chips>
      </Chips>
   </Main>
 </Container>
      </div>
     </div>
    );
};

export default Notification;