import * as Actions from './actions';
import initialState from '../store/initialState';

// UsersReducer
export const NovelsReducer = (state = initialState.novels, action) => {
    switch (action.type) {
          case Actions.FETCH_NOVELS :
              return {
                ...state,
                  list: [...action.payload]
              };
            default:
                return state
    }
}