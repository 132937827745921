import React,{useState} from 'react';
import styled from 'styled-components';
import {useDispatch} from "react-redux";
import NoImage from '../../assets/img/no_image.png';
import {push} from "connected-react-router"

const media = {
  sp: '@media(max-width: 650px)'
}

const Card = styled.div`
width: 100%;
max-width: 17em;
cursor: pointer;
padding:1em;
border-radius : 0.5em;
border: solid  2px #ececec;
background-color: #fff;
margin: 0.5em;
height: 23.5em;
transition: all ease-out .3s;
:hover {
  background-color: #f3f3f3;
  transition: all ease-out .3s;
}
img {
  border-radius: 0.5em;
  width: 17em;
  height: 17em;
  object-fit: contain;
}
`;

const Tag = styled.div`
    background-color: #FF385C;
    border-radius: 0.5rem;
    width: 100%;
    max-width: 5em;
    margin-right: 1em;
    p {
      text-align: center;
      color: #fff;
      padding: 0.5em;
      font-size: 0.6em;
    }
`;

const Title = styled.p`
    font-size: 1.1em;
    font-weight: bold;
    margin-top: 1.2em;
`;

const Tags = styled.div`
    display:flex;
    padding-top: 1em;
`;

const NotificationChip = (props) => {
    const dispatch = useDispatch();
    const images = (props.images.length > 0) ? props.images : [{path:NoImage}]

 return (
<>
        <Card onClick={() => dispatch(push('/post/novel/'+props.id))}>      
            <img src={images[0].path} alt="画像" />
            <Title>{props.title}</Title>

            <Tags>
             <Tag><p>{props.old}</p></Tag>
             <Tag><p>{props.coupling}</p></Tag>
            </Tags>
        </Card>
</>
    );
};

export default NotificationChip;