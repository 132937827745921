import React from 'react';
import styled from 'styled-components';

const Form = styled.div`
margin-top:1em;
    p {
        text-align:left;
        padding:0 0 0.5em 0;
        font-weight:bold;
    }
`;

const Input = styled.input`
    display: block;
    width: 90%;
    color:#FF385C;;
    height: 44px;
    padding: 5px 5%;
    border: 1px solid #ccc;
    border-radius: 27px;
    background-clip: padding-box;
    background-color: #fff;
    font-family: 'HelveticaNeue','Arial', sans-serif;
    font-size: 105%;
    letter-spacing: .8px;
    margin: 1em 0 1.5em 0;
`;

const SignInput = (props) => {
 return(
<Form>
<p>{props.label}</p>
  <Input
  placeholder={props.placeholder}
  type={props.type}
  name={props.name}
  id={props.id}
  className={props.class}
  required={props.required}
  autocomplete={props.complete}
  onChange={props.onChange}
  value={props.value}
  />
</Form>
 )
}

export default SignInput;