import React from 'react';
import styled from 'styled-components';
import {push} from "connected-react-router"
import {useDispatch} from "react-redux";

const Button = styled.button`
    margin: 0.5em 0;
    text-align: left;
    font-size: 15vw;
    border:solid 1.5px #FF385C;
    background-color: #fff;
    width: 100%;
    height: 60px;
    color: #FF385C;
    cursor: pointer;
    padding: 0 1em;
    transition: all ease-out .3s;
    :hover {
        background-color:#FF385C;
        color: #fff;
        transition: all ease-out .3s;
    }
`;

    const AccountButton = (props) => {
        const dispatch = useDispatch()
        return (
           <Button onClick={() => dispatch(push('/user/info/'+props.id))}>
              ユーザー情報
           </Button>
        )
}

export default AccountButton;