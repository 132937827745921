import React from 'react';
import {Main,Update, About,Question,More} from '../components/Home';
import {GoodBtn} from '../components/UIkit';
import ImageSlider from '../components/UIkit/ImageSlider';
import SliderData from '../components/UIkit/SliderData';

const Home = () => {

 return(
     <div>
         <ImageSlider slides={SliderData} />
         {/* <Main /> */}
         <Update />
         <About />
         <More />
         <Question />
     </div>
 )
}

export default Home;