import React from 'react';
import {UpDateList} from '../UIkit';

const Update = () => {

        const UpdateLists = [
          {
            data: '2021/07/15',
            text: 'サイトを刷新しました。'
          },
          {
            data: '2021/07/14',
            text: '企画を終了しました。サイトの使用を修正'
          },
          {
            data: '2021/07/10',
            text: '文言追加'
          },
          {
            data: '2021/05/20',
            text: 'サイト微修正'
          },
          {
            data: '2021/05/10',
            text: 'サイト微修正'
          },
          {
            data: '2021/04/29',
            text: '情報公開'
          },
          {
            data: '2021/04/11',
            text: 'サイト情報更新'
          },
          {
            data: '2021/04/04',
            text: '作品投稿機能実装'
          },
          {
            data: '2021/03/28',
            text: '会員登録・ログイン機能実装'
          },
          {
            data: '2021/03/22',
            text: '企画サイトの公開'
          },
          {
            data: '2021/03/20',
            text: 'Twitterで企画の告知'
          }
          ]

 return(
    <div className="common__contents">
    <div className="common__size">
    <h2>Charge Log</h2>
     <div>
     {UpdateLists.map((Lists,List) => {
        return <UpDateList key={List} data={Lists.data} text={Lists.text} />
    })}
     </div>
     </div>
     </div>
 )
}

export default Update;