import React from 'react';
import styled from 'styled-components';
import {useDispatch} from "react-redux";
import {push} from 'connected-react-router';

const Button = styled.div`
    margin: 3em auto;
    padding: 1.1em 3em;
    background-color: #FF385C;
    transition: all ease-out .3s;
    color: #fff;
    border-radius:2em;
    border: none;
    width:100%;
    max-width: 15em;
    margin-bottom:1em; 
    :hover{
    padding: 1.1em 3em;
    background: #c63751;
    color: #fff;
    transition: all ease-out .3s;
    border-radius:2em; 
    cursor:pointer;
    }
`;

const Top = styled.div`
margin: 5em 0;
`;

const Complete = () => {
    const dispatch = useDispatch();
 return(
<div className="common__contents">
    <div className="common__size">
        <Top>
        <h2>投稿いただき、ありがとうございました。</h2>

        <Button
onClick= {() => dispatch(push('/'))}
>
         ホームへ戻る
        </Button>

        <Button
onClick= {() => dispatch(push('/'))}
>
         投稿一覧へ戻る
        </Button>
        </Top>
    </div>
</div>
 )
}

export default Complete;