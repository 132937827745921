import React, {useEffect} from 'react'; // 1
import {fetchUsers} from "../reducks/users/operations"; // 2
import {useDispatch, useSelector} from "react-redux";
import { UserCard } from "../components/UIkit";
import { getUsers } from "../reducks/users/selectors";
import styled from 'styled-components';
import {Title} from '../components/Common';

const Users = styled.div`
 display:flex;
 justify-content: center;
 flex-wrap        : wrap;
`;

const Members = () => {
    const dispatch = useDispatch();
    const selector = useSelector(state => state);
    const users = getUsers(selector)
    
 useEffect(() => {
   dispatch(fetchUsers());
 },[])

return(
  <>
  <Title title={'参加メンバー'} />
  <div className="common__page">
                <div className="common__center">
 <Users>
  {users.length > 0 && (
    users.map(user => (
        <UserCard
            key={user.uid} uid={user.uid} email={user.email}  username={user.username} images={user.images}
        />
    ))
  )}
 </Users>
</div>
     </div>
</>
 )
}

export default Members;