import React, {useCallback} from 'react';
import {useDispatch} from "react-redux";
import {push} from 'connected-react-router';
import styled from 'styled-components';
import { PrimaryButton } from '../components/UIkit';

const Item = styled.div`
    border: solid 2px #FF385C;
    border-radius: 0.5em;
    background-color: #fff;
    transition: all ease-out .3s;
    margin-top: 1em;
    color: #FF385C;
    padding: 0.3em 2em;
    :hover{
        background-color: #FF385C;
        transition: all ease-out .3s;
        color: #fff !important;
    }
`;

const Top = styled.div`
margin: 5em 0;
`;

const Text = styled.p`
 text-align: center;
 padding: 1em 0 2em 0;
`;

const FirstPost = () => {
    const dispatch = useDispatch();

    const SignIn = [
        {
            title: 'イラスト・漫画を投稿する',
            path: '/forms/images',
            cName: 'nav-text'
        },
        {
            title: '小説を投稿する',
            path: '/forms/novels',
            cName: 'nav-text'
        }
    ]

    const goToHome = useCallback(() => {
        dispatch(push('/'));
       }, []);

return(
<div className="common__contents">
    <div className="common__size">
<Top>
<h2>作品投稿</h2>
<Text>投稿するコンテンツを選択してください。</Text>
 <>
     {SignIn.map((item1, index1) => {
            return (
                <Item onClick= {() => dispatch(push(item1.path))} key={index1} className={item1.cName}><p><span>{item1.title}</span></p></Item>
            )
        })}
    </>

    <PrimaryButton 
     label={"ホームへ戻る"}
     onClick={() => dispatch(goToHome)}
     />

    </Top>
    </div>
</div>
 )
}

export default FirstPost;