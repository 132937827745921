import React,{ useEffect,useState,useCallback } from 'react';
import { useSelector,useDispatch } from 'react-redux';
import {db} from '../firebase/index';
import styled from 'styled-components';
import { push } from 'connected-react-router';
import {PostImage} from '../components/UIkit';
import HTMLReactParser from 'html-react-parser';
import { PrimaryButton,PostedImage } from '../components/UIkit';

const media = {
  sp: '@media(max-width: 650px)'
}

const Description = styled.div`
padding:1em;
border-radius : 0.5em;
border: solid  2px #ececec;
background-color: #fff;
width: 100%;
max-width: 50em;
margin: 1em auto;
${media.sp} {
  max-width: 15.5em;
   }
`;

const Top = styled.div`
margin: 5em 0;
`;

const Name = styled.div`
display: flex;
align-items: center;
justify-content: center;
padding: 1.5em 0;
span{
  padding-left: 2em;
}
`;

const Chip = styled.p`
background-color: #FF385C;
border-radius: 0.5rem;
width: 9em;
color: #fff !important;
font-size: 0.9em;
padding: 1em 0;
text-align: center;
margin: 0 0.5em;
`;

const Tags = styled.div`
    display:flex;
    padding-top: 1em;
    justify-content: center;
`;

const Item = styled.div`
 padding: 3em 0;
`;

    // -------------------------------------
    //  returnCodeToBr関数
    // -------------------------------------  
    const returnCodeToBr = (text) => {
      if(text === "") {
        return text
      } else {
        return HTMLReactParser(text.replace(/\r?\n/g,'<br />'))
      }
    };

const ViewImage = () => {
  const dispatch = useDispatch();
    const selector = useSelector((state) => state);
    const path = selector.router.location.pathname;
    const id = path.split('/post/image/')[1];
    const [image, setImage] = useState(null);

    const goToImages = useCallback(() => {
      dispatch(push('/post/images'));
     }, []);

    useEffect (() => {
        db.collection('img').doc(id).get()
        .then(doc => {
           const data = doc.data();
           setImage(data)
        })
     }, []);

return(
  <div className="common__contents">
    <div className="common__size">
 <Top>
 <h2>投稿作品</h2>
     {image && (
       <>
     <PostedImage images={image.images} />
<Item>
     <h3>{image.title}</h3>
     <Name><Chip>投稿ユーザー</Chip><p><span>{image.name + '　' + '様'}</span></p></Name>
     <Description>{returnCodeToBr(image.description)}</Description>
     <Tags>
             <Chip>{image.old}</Chip>
             <Chip>{image.coupling}</Chip>
            </Tags>
</Item>

</>
   )}

<PrimaryButton 
     label={"一覧へ戻る"}
     onClick={() => dispatch(goToImages)}
     />

 </Top>
 </div>
</div>
 )
}

export default ViewImage;