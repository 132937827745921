import React from 'react';
import styled from 'styled-components';
import {push} from "connected-react-router"
import {useDispatch} from "react-redux";

const Button = styled.button`
    background-color: #FF385C;
    border-radius: 10em;
    color: rgb(255, 255, 255);
    display: block;
    font-size: 14px;
    padding: 1em 5em;
    margin: 3.5em auto;
    transition: all ease-out .3s;

    :hover {
    background-color: #c63751;
    color: rgb(255, 255, 255);
    display: block;
    font-size: 14px;
    transition: all ease-out .3s;
    }
`;

    const UserButton = (props) => {
        const dispatch = useDispatch()
        return (
           <Button onClick={() => dispatch(push('/user/edit/'+props.id))}>
              編集する
           </Button>
        )
}

export default UserButton;