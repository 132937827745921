import React from 'react';
import {Main,Update, About,Question,More} from '../components/Home';

const FirstHome = () => {

 return(
     <div>
         <Main />
         <Update />
         <About />
         <More />
         <Question />
     </div>
 )
}

export default FirstHome;