import React,{useState} from 'react';
import {useDispatch,useSelector} from "react-redux";
import {push} from 'connected-react-router';
import {getIsSignedIn,getUserId} from "../../reducks/users/selectors";
import {SignOutButton,AccountButton} from '../UIkit';

// icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars,faTimes} from "@fortawesome/free-solid-svg-icons";
import styled from 'styled-components';

const Nav = styled.div`
    position: fixed;
    top: 1.5em;
    left: 1.5em;
    background-color: #FF385C;
    z-index: 9999;
    box-shadow: 0px 3px 15px rgb(0 0 0 / 20%);
    border-radius: 50%;
    height: 4em;
    -webkit-transition: all ease-out .3s;
    transition: all ease-out .3s;
    cursor: pointer;
    width: 4em;
    text-align: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
`;

const NavItem = styled.div`
   width: 100%;
`;

const Toggle = styled.div`
   background-color: #fff;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
`;

const Menu = () => {
    const dispatch = useDispatch();
    const selector = useSelector((state) => state);
    const isSignedIn = getIsSignedIn(selector);
    const [sidebar,setSidebar] = useState(false);
    const showSidebar = () => setSidebar(!sidebar)
    const uid = getUserId(selector);


    const SignIn = [
        {
            title: 'Home',
            path: '/home',
            cName: 'nav-text'
        },
        {
            title: 'パスワードリセット',
            path: '/user/reset',
            cName: 'nav-text'
        },
        {
            title: '投稿する',
            path: '/post/first',
            cName: 'nav-text'
        },
        {
            title: 'マンガ・イラスト一覧',
            path: '/post/images',
            cName: 'nav-text'
        },
        {
            title: '小説一覧',
            path: '/post/novels',
            cName: 'nav-text'
        },
        {
            title: '参加メンバー',
            path: '/members',
            cName: 'nav-text'
        }

    ]

    // const SignOut = [
    //     {
    //         title: 'Home',
    //         path: '/',
    //         cName: 'nav-text'
    //     },
    //     {
    //         title: 'ログイン',
    //         path: '/signin',
    //         cName: 'nav-text'
    //     },
    //     {
    //         title: '新規登録',
    //         path: '/signup',
    //         cName: 'nav-text'
    //     },
    //     {
    //         title: 'マンガ・イラスト一覧',
    //         path: '/post/images',
    //         cName: 'nav-text'
    //     },
    //     {
    //         title: '小説一覧',
    //         path: '/post/novels',
    //         cName: 'nav-text'
    //     },
    //     {
    //         title: '参加メンバー',
    //         path: '/members',
    //         cName: 'nav-text'
    //     }
    // ]

    const SignOut = [
        {
            title: 'Home',
            path: '/',
            cName: 'nav-text'
        },
        {
            title: 'マンガ・イラスト一覧',
            path: '/post/images',
            cName: 'nav-text'
        },
        {
            title: '小説一覧',
            path: '/post/novels',
            cName: 'nav-text'
        },
        {
            title: '参加メンバー',
            path: '/members',
            cName: 'nav-text'
        }
    ]

 return(
    <>
     <Nav>
     <FontAwesomeIcon className="bar-icon" icon={faBars} onClick={showSidebar} />
    </Nav> 
    <div className={sidebar ? 'nav-menu active' : 'nav-menu'}>
      <NavItem onClick={showSidebar}>
      <Toggle>
          <FontAwesomeIcon className="close-icon" icon={faTimes} />
        </Toggle>
        <ul>
{/* {isSignedIn ? (
    <>
     {SignIn.map((item1, index1) => {
            return (
                <li key={index1} className={item1.cName}><a onClick= {() => dispatch(push(item1.path))}><span>{item1.title}</span></a></li>
            )
        })}
     <AccountButton id={uid} />
     <SignOutButton />
    </>
):(
    <>
     {SignOut.map((item2, index2) => {
            return (
                <li key={index2} className={item2.cName}><a onClick= {() => dispatch(push(item2.path))}><span>{item2.title}</span></a></li>
            )
        })}
    </>
)} */}

    <>
     {SignOut.map((item2, index2) => {
            return (
                <li key={index2} className={item2.cName}><a onClick= {() => dispatch(push(item2.path))}><span>{item2.title}</span></a></li>
            )
        })}
    </>
        </ul>
      </NavItem>
    </div>
    </>
)
}

export default Menu;