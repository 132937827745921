import React from 'react';
import styled from 'styled-components';

const InputItem = styled.input`
border: 3px solid #e5e5e5;
    background-color: #fbfbfb;
    width: 100%;
    max-width: 40em;
    height: 2.２em;
    border-radius: 2em;
    padding:0.5em 1.5em;
    font-size:1.2em;
    transition: all ease-out .3s;
    color:#FF385C;
    outline: 0;
    :focus {
        border: 3px solid #FF385C;
        transition: all ease-out .3s;
}
`;

const Input = () => {
 return(
    <InputItem type="text"/>
 )
}

export default Input;