import React from 'react';
import styled from 'styled-components';

const TitleArea = styled.div`
 background-color: #FF385C;
 padding: 11em 0 7em 0;
 text-align: center;
 h2{
     color: #fff;
 }
`;

const Title = (props) => {
 return(
 <TitleArea>
    <h2>{props.title}</h2>
 </TitleArea>
 )
}

export default Title;