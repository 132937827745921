import * as Actions from './actions';
import initialState from '../store/initialState';

// UsersReducer
export const UsersReducer = (state = initialState.users, action) => {
    switch (action.type) {
        case Actions.SIGN_IN:
            return {
                ...state,
                ...action.payload
            };
            case Actions.SIGN_OUT:
                return {
                    ...action.payload
                }; 
            case Actions.FETCH_USERS : // 5
                return {
                    ...state, // 6
                    list: [...action.payload] // 7
                };           
            default:
                return state
    }
}