import React from 'react';
import { AllNovel,AllImage } from './';

const Posts = () => {
 return (
<>
 <AllNovel />
 <AllImage />
</>
    );
};

export default Posts;