import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { signOut } from '../../reducks/users/operations';

const Button = styled.button`
    margin: 0.5em 0;
    text-align: left;
    font-size: 18px;
    background-color:#FF385C;
    width: 100%;
    height: 60px;
    color: #fff;
    border: 0;
    cursor: pointer;
    padding: 0 1em;
    transition: all ease-out .3s;
    :hover {
        background-color:#c63751;
        transition: all ease-out .3s;
    }
`;

const SignOutButton = (props) => {
    const dispatch = useDispatch();
 return(
  <Button onClick={() => dispatch(signOut())}>
      ログアウトする
  </Button>
 )
}

export default SignOutButton;