import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";

const SnsItem = styled.div`
 position: fixed;
 bottom: 1.5em;
 right:1.5em;
 background-color: #FF385C;
 z-index: 99;
 box-shadow: 0px 3px 15px rgb(0 0 0 / 20%);
 border-radius: 50%;
 height: 4em;
 transition: all ease-out .3s;
 cursor: pointer;
 width: 4em;
 text-align: center;
 display: flex;
    align-items: center;
    justify-content: center;
 :hover {
    transition: all ease-out .3s;
    background-color: #c63751;
 }
`;

const Sns = () => {
 return(
    <div>
     <a href="https://twitter.com/BeispielEin" target="blank">
     <SnsItem>
      <FontAwesomeIcon className="icon" icon={faTwitter} />
     </SnsItem>
     </a>
     </div>
 )
}

export default Sns;