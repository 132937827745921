import React from 'react';
import {QuestionButton} from'../UIkit';

const Question = () => {

    const QuestionLists = [
        {
          text: 'どんな企画でしょうか？',
          answer: '「Guraとギガ」をテーマにした作品を投稿する企画です。企画登録後、当サイトにて、対象の作品を投稿していただきます。企画終了日に投稿は終了します。'
        },
        {
            text: '腐向けの作品について',
            answer: '「Guraxギガ/ギガxGura」らカップリング投稿は問題有ません。しかし、これらの投稿には必ずタグを使用してください。'
          },
          {
            text: '作品を消して欲しい',
            answer: '作品の削除は、登録ユーザー名と該当作品を記入して管理主に連絡ください。'
          },
          {
            text: '年齢指定作品について',
            answer: '「R18/R15」らの投稿は問題あリません。しかし、これらの投稿には必ずタグを使用してください。'
          },
          {
            text: '投稿後の取り組み',
            answer: '場合により "コンビニプリント"  または "冊子" にしたいと考えています。'
          },
          {
            text: '投稿後の作品について',
            answer: '企画投稿後の作品は「sns・pixiv」らに投稿可能です。しかし投稿時に「企画タグor企画使用の文言」を記載してください。'
          }
      ]

 return(
    <div className="common__contents back-gray">
    <div className="common__size">
    <h2>Question</h2>
    {QuestionLists.map((Lists,List) => {
        return <QuestionButton key={List} text={Lists.text} answer={Lists.answer} />
    })}
     </div>
     </div>
 )
}

export default Question;