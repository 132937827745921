import React from 'react';

const About = () => {

 return(
    <div className="common__contents back-gray">
    <div className="common__size">
    <h2>About</h2>
     <p>当企画は、2021年7月15日に投稿を終了しました。<br />参加いただきました方へ、本当にありがとうございました。<br />以後は、作品を自由に閲覧することが可能です。是非、お楽しみください。</p>
     <p>当企画は <strong>毛魂</strong> の <strong>Gura と ギガ</strong> をテーマにしたファンによる <strong>Webアンソロジー企画</strong> になります。</p>
     <p>また、ファン個人の企画のため、製作者様には一切関係ございません。</p>
     <div className="spacer" />
     <p>参加方法は、会員登録後に個々が作品を投稿することができ、企画終了日まで投稿ができるので楽しんでいただけると嬉しいです。</p>
     <p>また。企画終了日まで「投稿・作品閲覧・メンバー一覧閲覧」は <strong>登録者様に限定</strong> になっています。</p>
     <div className="spacer" />
     </div>
     </div>
 )
}

export default About;