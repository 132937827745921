// signInAction
export const SIGN_IN = "SIGN_IN";
export const signInAction = (userState) => {
    return {
        type: "SIGN_IN",
        payload:  {
            isSignedIn: true,
            role: userState.role,
            uid: userState.uid,
            username: userState.username,
            email: userState.email,
            description: userState.description,
            participation: userState.participation,
            twitterUrl: userState.twitterUrl,
            pixivUrl: userState.pixivUrl,
            otherUrl: userState.otherUrl,
            images: userState.images
        }
    }
};

// signOutAction
export const SIGN_OUT = "SIGN_OUT";
export const signOutAction = () => {
    return {
        type: "SIGN_OUT",
        payload:  {
            isSignedIn: false,
            role: "",
            uid: "",
            username: "",
            email: "",
            description: "",
            participation: "",
            twitterUrl: "",
            pixivUrl: "",
            otherUrl: ""
        }
    }
};


export const FETCH_USERS = "FETCH_USERS"; 
export const fetchUsersAction = (users) => { 
   return {
       type: "FETCH_USERS", // 3
       payload: users // 4
   }
};