import React, {useEffect} from 'react';
import styled from 'styled-components';
import {CardImage} from '../components/UIkit';
import {useDispatch, useSelector} from "react-redux";
import {fetchNotifications} from "../reducks/Img/operations";
import {getNotifications} from "../reducks/Img/selectors";
import {Title} from '../components/Common';

const media = {
  sp: '@media(max-width: 650px)'
}

const Chips = styled.main`
  display: flex;
  justify-content:center;
  align-items: center;
  -webkit-flex-wrap: wrap;          
  -ms-flex-wrap    : wrap;         
  flex-wrap        : wrap;

`;

const Text = styled.p`
 font-weight: bold;
 color: #FF385C;
 padding: 3em 0 2em 0;
`;

const AllImage = () => {
    const dispatch = useDispatch()
    const selector = useSelector(state => state);
    const notifications = getNotifications(selector)
    console.log(notifications)
 
    useEffect(() => {
        dispatch(fetchNotifications());
    },[])

    return (
      <>
      <Title title={'イラスト・漫画の作品'} />
      <div className="common__page">
        <div className="common__center">
        <Chips>
{notifications.length > 0 ? (
  notifications.map(notification => 
    <CardImage
    key={notification.id} id={notification.id} main={notification.main} title={notification.title} coupling={notification.coupling} old={notification.old} images={notification.images}
   /> )
) : (
  <>
   <Text>投稿がありません</Text>
  </>
)}
        </Chips>
     </div>
     </div>
     </>
    );
};

export default AllImage;