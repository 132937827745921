import React from 'react';
import styled from 'styled-components';
import top from '../../assets/img/anthology-top.png';

const MainImage = styled.div`
  width: 100%;
  margin-top: 4.2em;
  height: 0;
  padding-top: calc(320 / 1000 * 100%); 
  background: url(${top}) center center / cover no-repeat;
`;

const Main = () => {
 return(
       <MainImage />
 )
}

export default Main;