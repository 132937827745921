import React from 'react';
import {MoreItem} from '../UIkit';
import styled from 'styled-components';

const Item = styled.div`
 text-align:center;
 margin: 0 auto;
`;

const More = () => {

    const MoreLists = [
       {
        main: '開催日時',
        text: '2021年05月01日 ~ 2021年07月015日'
       },
       {
        main: '企画終了日',
        text: '2021年07月15日 23:59'
       },
        {
          main: '費用',
          text: '参加する際の費用はいただきません。'
        },
        {
          main: '参加方法',
          text: '企画にフォームから登録をして作品を投稿してください。'
        },
        {
            main: '年齢',
            text: '18歳以上の方を対象にしています。'
          },
          {
            main: 'カップリング',
            text: '15禁・18禁の作品を投稿する場合には、タグ指定をお願いします。'
          },
          {
            main: '作品ファイル',
            text: 'jpg・png・テキストデータでの提出をお願いします。'
          },
          {
            main: 'コンタクト',
            text: 'Twitterからコメント・DMください。'
          },
          {
            main: '企画のPR',
            text: 'Twitterで #Guraさんとギガは良いゾ! と投稿ください。'
          }
      ]

 return(
    <div className="common__contents">
    <div className="common__size">
     <h2>More</h2>
     <Item>
    {MoreLists.map((Lists,List) => {
        return <MoreItem key={List} main={Lists.main} text={Lists.text} />
    })}
     </Item>
     </div>
     </div>
 )
}

export default More;