import { v4 as uuidv4 } from "uuid";
import banner1 from '../../assets/img/gura-giga-images.png';
import banner2 from '../../assets/img/gura-giga-novels.png';
import banner3 from '../../assets/img/gura-giga-novels2.png';

const  SliderData = [
    {
        image: banner1,
    },
    {
        image: banner2,
    },
    {
        image: banner3,
    }
]

export default SliderData;