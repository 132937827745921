import React,{useCallback,useState} from 'react';
import {useDispatch} from "react-redux";
import {SignInput,PrimaryButton} from '../components/UIkit';
import {ResetPassword} from "../reducks/users/operations"
import {push} from 'connected-react-router';
import styled from 'styled-components';

const Form = styled.div`
    p {
        padding: 0.8em 0;
        line-height: 0.5em;
        cursor: pointer;
    }
`;

const Texts = styled.div`
        text-align: center;
        padding-top: 2em;
        p{
            margin: 0 auto;
            color: #FF385C;
        }
`;

const Reset = () => {
    const dispatch = useDispatch();
    const [email, setEmail] = useState("");
    const inputEmail = useCallback((event) => {
        setEmail(event.target.value)
    },[setEmail]);

 return(
    <div className="common__sub-contents">
    <div className="common__size">
    <Form>
     <div className="common__center">
      <h2>パスワードのリセット</h2>
     <SignInput
      label={"メールアドレス"}
      placeholder={"メールアドレス"}
      type={"email"}
      required={true}
      name={"email"}
      id={"email"}
      className={"email"}
      autocomplete={"off"}
      value={email}
      onChange={inputEmail}
     />

     <PrimaryButton 
     label={"リセット"}
     onClick={() => dispatch(ResetPassword(email))}
     />

     <Texts>
     <p onClick={() => dispatch(push('/signUp'))}>アカウントをお持ちでない方はこちら</p>
     </Texts>
     </div>
  </Form>
  </div>
  </div>
 )
}

export default Reset;