import React, {useEffect,useCallback} from 'react';
import {fetchUsers} from "../reducks/users/operations";
import {useDispatch, useSelector} from "react-redux";
import styled from 'styled-components';
import {Title} from '../components/Common';
import { PrimaryButton } from '../components/UIkit';
import {push} from 'connected-react-router';

const Text = styled.p`
 text-align:center;
`;

const Buttons = styled.div`
 padding: 3em 0 1em 0;
`;

const EndReset = () => {
    const dispatch = useDispatch();
    
 useEffect(() => {
   dispatch(fetchUsers());
 },[])

 const goToHome = useCallback(() => {
  dispatch(push('/'));
 }, []);

 const goToLogin = useCallback(() => {
  dispatch(push('/signin'));
 }, []);

return(
  <>
  <Title title={'メールをお送りしました'} />
  <div className="common__page">
  <div className="common__center">
   <Text>ありがとうございます。<br />パスワードリセット用のメールをお送りしました。<br />改めてログインお願いします。</Text>

  <Buttons>
  <PrimaryButton 
     label={"ログインへ"}
     onClick={() => dispatch(goToLogin)}
     />

   <PrimaryButton 
     label={"ホームへ戻る"}
     onClick={() => dispatch(goToHome)}
     />
  </Buttons>

　</div>
   </div>
</>
 )
}

export default EndReset;