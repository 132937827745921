import {createSelector} from 'reselect';

const usersSelector = (state) => state.users;

export const getUserId = createSelector(
    [usersSelector],
    state => state.uid
)

export const getIsSignedIn = createSelector(
    [usersSelector],
    state => state.isSignedIn
)

export const getUsername = createSelector(
    [usersSelector],
    state => state.username
)

export const getUseremail = createSelector(
    [usersSelector],
    state => state.email
)

export const getUserdescription = createSelector(
    [usersSelector],
    state => state.description
)

export const getUserparticipation = createSelector(
    [usersSelector],
    state => state.participation
)

export const getUsertwitterUrl = createSelector(
    [usersSelector],
    state => state.twitterUrl
)

export const getUserpixivUrl = createSelector(
    [usersSelector],
    state => state.pixivUrl
)

export const getUserotherUrl = createSelector(
    [usersSelector],
    state => state.otherUrl
)

export const getUsers = createSelector(
    [usersSelector],
    state => state.list
);
