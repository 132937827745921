import React from 'react';
import styled from 'styled-components';
import image from '../../assets/img/logo.png';

const NavBar = styled.header`
 position: fixed;
 top:0;
 background-color: #fff;
 box-shadow: 0px 3px 15px rgb(0 0 0 / 20%);
 z-index: 99;
 width: 100%;
 display: flex;
 justify-content: center;
 text-align: center;
 h1 {
     margin: 0 auto;
     padding: 0.7em 0;
     color: #FF385C;
 }
`;

const Image = styled.img`
 width: 100%;
 max-width: 320px;
 height: auto;
 padding: 0.5em 0;
`;

const Header = () => {
 return(
     <NavBar>
    <div className="common__size">
     <h1><Image src={image} alt="Guraさんとギガあh良いゾ!" /></h1>
     </div>
     </NavBar>
 )
}

export default Header;