import React,{useState} from 'react';
import styled from 'styled-components';

const Button = styled.button`
background-color: #FF385C;
    width:100%;
    border-radius: 0.5em;
    padding: 0.5em 0;
    border: none;
    font-size: 1em;
    cursor: pointer;
    color: #fff;
    outline: none;
    transition: all ease-out .3s;
    margin: 0.5em auto;
    :hover {
        background-color: #c63751;
        transition: all ease-out .3s;
    }
`;

const Answer = styled.div`
max-width: 50em;
    border-radius: 0.5em;
    margin: 0.5em auto;
    padding-bottom:1em;
`;

const QuestionButton = (props) => {
    const [power, setPower] = useState(null);
 return(
    
     <div>
         <Button onClick={() => setPower(prevState => !prevState)}>
         {props.text}
     </Button>
     <Answer>
     { power ? <p>{props.answer}</p> : ''}
     </Answer>
     </div>
    
 )
}

export default QuestionButton;