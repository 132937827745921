import React from 'react'; 
import styled from 'styled-components';
import NoImage from '../../assets/img/no_image.png';

const Image = styled.img`
 width: 15em;
 height: 15em;
 border-radius: 50%;
`;

const ImageBox = (props) => {
    const images = (props.images.length > 0) ? props.images : [{path:NoImage}]
return(
 <>
     <Image src={images[0].path} alt="ユーザーイメージ" />
 </>
 )
}

export default ImageBox;