import React,{ useEffect,useState,useCallback } from 'react'; // 1
import { useSelector,useDispatch } from 'react-redux';
import {db} from '../firebase/index';
import styled from 'styled-components';
import { push } from 'connected-react-router';
import { getUserId } from '../reducks/users/selectors';
import {ImageBox,PrimaryButton } from '../components/UIkit';
import HTMLReactParser from 'html-react-parser';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkSquareAlt } from "@fortawesome/free-solid-svg-icons";

const Content = styled.div` 
margin: 0 auto;
 h3 {
   font-size: 1.1em;
   font-weight: bold;
   padding: 1em 0;
   text-align: center;
 }
 p{
   text-align:left;
   color: #FF385C;
  
 }
`;

const Item = styled.div`
 padding: 1em 0;
 p {
   text-align: center;
 }
 a {
  text-decoration: none;
  color:#FF385C;
  cursor:pointer;
}
`;

const ItemText = styled.div`
 padding: 1em 0;
 p {
   text-align: left;
 }
 a {
  text-decoration: none;
  color:#FF385C;
  cursor:pointer;
}
`;

    // -------------------------------------
    //  returnCodeToBr関数
    //  HTML-react-parser で 正規表現を使用して <br> タグを開業につける
    // -------------------------------------  
    const returnCodeToBr = (text) => {
      if(text === "") {
        return text
      } else {
        return HTMLReactParser(text.replace(/\r?\n/g,'<br />'))
      }
    };

const User = () => {
    const selector = useSelector((state) => state);
    const dispatch = useDispatch();
    const path = selector.router.location.pathname;
    const id = path.split('/user/info/')[1];
    const uid = getUserId(selector);
    const [user, setUser] = useState(null);

    const goToEdit = useCallback(() => {
      dispatch(push('/user/edit/'+uid));
     }, []);

    useEffect (() => {
        db.collection('users').doc(id).get()
        .then(doc => {
           const data = doc.data();
           setUser(data)
        })
     }, []);

return(
  <div className="common__sub-contents">
  <div className="common__size">
 <h2>ユーザー情報</h2>
     {user && (
     <Content>
     {/* <p>{user.images}</p> */}
     <ImageBox images={user.images} />
<Item>
<h3>ユーザー名</h3>
     <p>{user.username}</p>
</Item>

     <Item>
     <h3>メールアドレス</h3>
     <p>{user.email}</p>
     </Item>

     <ItemText>
     <h3>自己紹介</h3>
     {user.description.length > 0 ? (
 <p>{returnCodeToBr(user.description)}</p>
) : (
 <p>入力されていません</p>
)}
     </ItemText>

     <Item>
     <h3>TwitterURL</h3>
     {user.twitterUrl.length > 0 ? (
 <p><FontAwesomeIcon className="link-icon" icon={faExternalLinkSquareAlt} /><a href={user.twitterUrl} target="blank">{user.twitterUrl}</a></p>
) : (
 <p>リンクはありません</p>
)}
     </Item>

     <Item>
     <h3>pixivURL</h3>
     {user.pixivUrl.length > 0 ? (
  <p><FontAwesomeIcon className="link-icon" icon={faExternalLinkSquareAlt} /><a href={user.pixivUrl} target="blank">{user.pixivUrl}</a></p>
) : (
  <p>リンクはありません</p>
)}
     </Item>

     <Item>
     <h3>otherURL</h3>
     {user.otherUrl.length > 0 ? (
  <p><FontAwesomeIcon className="link-icon" icon={faExternalLinkSquareAlt} /><a href={user.otherUrl} target="blank">{user.otherUrl}</a></p>
) : (
  <p>リンクはありません</p>
)}
     </Item>
     </Content>
   )}

<PrimaryButton 
     label={"編集する"}
     onClick={() => dispatch(goToEdit)}
     />

 </div>
 </div>
 )
}

export default User;