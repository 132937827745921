import React,{ useEffect,useState,useCallback } from 'react'; // 1
import {db} from '../firebase/index';
import styled from 'styled-components';
import {ImageBox} from '../components/UIkit';
import HTMLReactParser from 'html-react-parser';
import { PrimaryButton } from '../components/UIkit';
import { push } from 'connected-react-router';
import { useSelector,useDispatch } from 'react-redux';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkSquareAlt } from "@fortawesome/free-solid-svg-icons";

const TextArea = styled.p`
 text-align: left !important;
 border: solid  2px #ececec;
background-color: #fff;
border-radius : 0.5em;
margin: 1em auto;
padding:1em;
`;

const Content = styled.div` 
margin: 0 auto;
 h3 {
   font-size: 1.1em;
   font-weight: bold;
   padding: 1em 0;
   text-align: center;
 }
 p{
   text-align:center;
   color: #FF385C !important;
 }
`;

const UrlBox = styled.p`
padding:1em;
text-align: left;
border-radius : 0.5em;
border: solid  2px #ececec;
background-color: #fff;
width: 100%;
max-width: 25em;
margin: 1em auto;
a {
  text-decoration: none;
  color:#FF385C;
  cursor:pointer;
}
`;

const Item = styled.div`
 padding: 1em 0;
`;

    // -------------------------------------
    //  returnCodeToBr関数
    // -------------------------------------  
    const returnCodeToBr = (text) => {
      if(text === "") {
        return text
      } else {
        return HTMLReactParser(text.replace(/\r?\n/g,'<br />'))
      }
    };

const ViewUser = () => {
    const dispatch = useDispatch();
    const selector = useSelector((state) => state);
    const path = selector.router.location.pathname;
    const id = path.split('/user/view/')[1];
    const [user, setUser] = useState(null);

    const goToUsers = useCallback(() => {
      dispatch(push('/members'));
     }, []);

    useEffect (() => {
        db.collection('users').doc(id).get()
        .then(doc => {
           const data = doc.data();
           setUser(data)
        })
     }, []);

return(
  <div className="common__sub-contents">
    <div className="common__size">
 <h2>ユーザー情報</h2>
     {user && (
     <Content>
     <ImageBox images={user.images} />
<Item>
<h3>ユーザー名</h3>
     <p>{user.username}</p>
</Item>

<Item>
     <h3>プロフィール</h3>
     {user.description.length > 0 ? (
 <TextArea>{returnCodeToBr(user.description)}</TextArea>
) : (
  <UrlBox><p>入力されていません</p></UrlBox>
)}
     </Item>

     <Item>
     <h3>TwitterURL</h3>
     {user.twitterUrl.length > 0 ? (
  <UrlBox><FontAwesomeIcon className="link-icon" icon={faExternalLinkSquareAlt} /><a href={user.twitterUrl} target="blank">{user.twitterUrl}</a></UrlBox>
) : (
  <UrlBox><p>リンクはありません</p></UrlBox>
)}
     </Item>

     <Item>
     <h3>pixivURL</h3>
     {user.pixivUrl.length > 0 ? (
  <UrlBox><FontAwesomeIcon className="link-icon" icon={faExternalLinkSquareAlt} /><a href={user.pixivUrl} target="blank">{user.pixivUrl}</a></UrlBox>
) : (
  <UrlBox><p>リンクはありません</p></UrlBox>
)}
     </Item>

     <Item>
     <h3>otherURL</h3>
     {user.otherUrl.length > 0 ? (
   <UrlBox><FontAwesomeIcon className="link-icon" icon={faExternalLinkSquareAlt} /><p><a href={user.otherUrl} target="blank">{user.otherUrl}</a></p></UrlBox>
) : (
  <UrlBox><p>リンクはありません</p></UrlBox>
)}
     </Item>
     </Content>
   )}

<PrimaryButton 
     label={"一覧へ戻る"}
     onClick={() => dispatch(goToUsers)}
     />
 </div>
</div>
 )
}

export default ViewUser;