import React from 'react';
import styled from 'styled-components';

const FooterBar = styled.footer`
 background-color: #3e3e3e;
 display: flex;
 justify-content: center;
 text-align: center;
 width: 100%;
 p {
     color: #fff;
     padding: 1em 0;
 }
`;

const Footer = () => {
 return(
     <FooterBar>
    <div className="common__size">
     <p><small>webアンソロジー Guraさんとギガは良いぞ!</small></p>
     </div>
     </FooterBar>
 )
}

export default Footer;