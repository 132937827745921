import React from 'react';
import styled from 'styled-components';

const Items = styled.div`
 display: flex;
 align-items: center;
 margin: 1em 0;
 flex-wrap: wrap;
 justify-content: left;
`;

const MainText = styled.p`
 background-color: #ddd;
 margin: 0 auto;
 border-radius: 0.5em;
 padding: 0.4em 0.7em;
 width: 8em;
 text-align:center;
`;

const Text = styled.p`
 color:#494949;
 padding-left: 1em;
`;

const MoreItem = (props) => {

 return(
    <Items>
        <div className="mb-Main-style">
        <MainText>{props.main}</MainText>
        </div>
        <div className="mb-style">
        <Text>{props.text}</Text>
        </div>
    </Items>
  
 )
}

export default MoreItem;