import React from 'react';
import {Route, Switch} from "react-router";
import {Home,Reset,SignIn,SignUp,User,UserEdit,SendMessage,Members,ViewUser,ViewImage,ViewNovel,Notification,NotificationForm,ImagePost,NovelPost,AllImage,AllNovel,FirstPost,Posts,Complete,Test,EndReset,FirstHome} from "./templates";
import Auth from './Auth';

const Router = () => {
    return(
        <Switch>
            <Route exact path={"(/)?"} component={Home} />
            <Route exact path={"/signin"} component={SignIn} />
            <Route exact path={"/signup"} component={SignUp} />
            <Route exact path={"/user/reset"} component={Reset} />
            <Route exact path={"/SendMessage"} component={SendMessage} />
            <Route exact path={"/post/novels"} component={AllNovel} />
            <Route exact path={"/post/images"} component={AllImage} />
            <Route exact path={"/members"} component={Members} />
            {/* <Route exact path={"/members"} component={Members} />
            <Route exact path={"/post/novels"} component={AllNovel} />
            <Route exact path={"/post/images"} component={AllImage} /> */}
            <Route exact path={"/test"} component={Test} />
            <Route exact path={"/sendEmail"} component={EndReset} />
            <Route path={"/post/image(/:id)?"} component={ViewImage} />
            <Route path={"/post/novel(/:id)?"} component={ViewNovel} />

            <Auth>
            <Route exact path={"/home"} component={FirstHome} />
            <Route path={"/user/edit(/:id)?"} component={UserEdit} />
            <Route exact path={"/user/reset"} component={Reset} />
            <Route path={"/user/info(/:id)?"} component={User} />
            <Route path={"/user/view(/:id)?"} component={ViewUser} />
            <Route exact path={"/notification"} component={Notification} />
            <Route path={"/notification/form(/:id)?"} component={NotificationForm} />
            <Route exact path={"/forms/novels"} component={NovelPost} />
            <Route exact path={"/forms/images"} component={ImagePost} />
            <Route exact path={"/post/first"} component={FirstPost} />
            <Route exact path={"/posts"} component={Posts} />
            <Route exact path={"/complete"} component={Complete} />
            </Auth>
        </Switch>
    )
}

export default Router;