import React,{useCallback,useState} from 'react';
import {useDispatch} from "react-redux";
import {signIn} from "../reducks/users/operations"
import {SignInput, PrimaryButton} from '../components/UIkit';
import {push} from 'connected-react-router';
import styled from 'styled-components';

const Form = styled.div`
    p {
        padding: 0.8em 0;
        cursor: pointer;
        
    }
`;

const Texts = styled.div`
        text-align: center;
        padding-top: 2em;
        p{
            margin: 0 auto;
            color: #FF385C;
        }
`;

const TextArea = styled.div`
 p {
    padding: 3em 0;
 }
`;

const SignIn = () => {
    const dispatch = useDispatch();

    const [email, setEmail] = useState(""),
          [password, setPassword] = useState("");

    const inputEmail = useCallback((event) => {
        setEmail(event.target.value)
    },[setEmail]);
    const inputPassword = useCallback((event) => {
        setPassword(event.target.value)
    },[setPassword]);

 return(
    <div className="common__sub-contents">
    <div className="common__size">
    <Form>
     <div className="common__center back__gray">
      <h2>ログイン</h2>
      <TextArea>
      <p>パスワード・メールアドレスが謝っている場合ログインできません。確認の上ログインしてください。</p>
      </TextArea>
     <SignInput
      label={"メールアドレス"}
      placeholder={"メールアドレス"}
      type={"email"}
      required={true}
      name={"email"}
      id={"email"}
      className={"email"}
      autocomplete={"off"}
      value={email}
      onChange={inputEmail}
     />

<SignInput
      label={"パスワード"}
      placeholder={"パスワード"}
      type={"password"}
      required={true}
      name={"password"}
      id={"password"}
      className={"password"}
      autocomplete={"off"}
      value={password}
      onChange={inputPassword}
     />

     <PrimaryButton label={"ログインする"} onClick={() => dispatch(signIn({
         email: email,
         password: password
         }))} />
     
     <Texts>
     <p onClick={() => dispatch(push('/signup'))}>アカウントをお持ちでない方はこちら</p>
     <p onClick={() => dispatch(push('/user/reset'))}>パスワードをお忘れの方はこちら</p>
     </Texts>
     </div>
  </Form>
  </div>
  </div>
 )
}

export default SignIn;