import {signInAction,signOutAction} from './actions';
import {push} from 'connected-react-router';
import {auth, db, FirebaseTimestamp} from '../../firebase/index';
import {fetchUsersAction} from './actions'; // 3

// export const signIn = () => {
//     return async (dispatch, getState) => {
//         const state = getState()
//         const isSignedIn = state.users.inSignedIn

//         if (!isSignedIn) {
//             const url = 'https://api.github.com/users/deatiger'

//             const response = await fetch(url)
//             .then(res => res.json())
//             .catch(() => null)

//          const username = response.login
         
//          dispatch(signInAction({
//              isSignedIn: true,
//              uid: "0001",
//              username: username
//          }))
//          dispatch(push('/'))
//         }
//     }
// }
const usersRef = db.collection('users')

// userEdit関数
export const userEdit = ({id,username, email,description,participation,twitterUrl,pixivUrl,otherUrl,images}) => {
    return async (dispatch) => {
      const timestamp = FirebaseTimestamp.now()

      if (username === "" || email === "") {
        alert("必須項目が見入力です")
        return false
    }

      const data = {
        created_at: timestamp,
        email: email,
        role: "customer",
        updated_at:  timestamp,
        username: username,
        description: description,
        participation: participation,
        twitterUrl: twitterUrl,
        pixivUrl: pixivUrl,
        otherUrl: otherUrl,
        images: images
      }
      return usersRef.doc(id).set(data, {merge: true})
      .then(()=>{
          dispatch(push('/home'))
      }).catch((error) => {
          throw new Error(error)
      })
    }
}

// ------------------------
// 認証リッスン関数 Authで認証
// onAuthStateChanged (戻り値としてullを返したりする)
// ------------------------
// export const listenAuthState = () => {
//     return async (dispatch) => {
//         return auth.onAuthStateChanged(user => {
//             // ユーザーが存在したら
//             if(user) {
//                const uid = user.uid
//                db.collection('users').doc(uid).get()
//                .then(snapshot => {
//                    const data = snapshot.data()
//       // ユーザーの情報を持ってくる
//                    dispatch(signInAction({
//                        isSignedin: true,
//                        role: data.role,
//                        uid: uid,
//                        username: data.username,
//                        email: data.email,
//                        description: data.description,
//                        participation: data.participation,
//                        twitterUrl: data.twitterUrl,
//                        pixivUrl: data.pixivUrl,
//                        otherUrl: data.otherUrl
//                    }))
//                })
//             } else {
//                 // 認証されてない時は
//                 dispatch(push('/signin'))
//             }
//         })
//     }
// }

export const listenAuthState = () => {
    return async (dispatch) => {
        return auth.onAuthStateChanged(user => {
            if(user) {
               usersRef.doc(user.uid).get()
               .then(snapshot => {
                   const data = snapshot.data()
                if (!data) {
                throw new Error('ユーザーデータが存在しません。')
                }
                // Update logged in user state
                   dispatch(signInAction({
                       role: data.role,
                       uid: user.uid,
                       isSignedIn: true,
                       username: data.username,
                       email: data.email,
                       description: data.description,
                       participation: data.participation,
                       twitterUrl: data.twitterUrl,
                       pixivUrl: data.pixivUrl,
                       otherUrl: data.otherUrl
                   }))
               })
            } else {
                dispatch(push('/signin'))
            }
        })
    }
};


export const signIn = ({email, password}) => {
    return async (dispatch) => {
       //  Validation
        if (email === "" || password === "") {
           alert("必須項目が見入力です")
           return false
        }
   
       //  signInWithEmailAndPassword で ログイン処理を設定する
        return auth.signInWithEmailAndPassword(email,password)
        .then(result => {
           //  ユーザー情報
         const user = result.user;
   
       //   もしもユーザーが存在したら
         if(user) {
             const uid = user.uid
             db.collection('users').doc(uid).get()
             .then(snapshot => {
                 const data = snapshot.data()
   
               //   siginInaction を呼び出して以下の情報に変更する
                 dispatch(signInAction({
                     isSignedin: true,
                     role: data.role,
                     uid: uid,
                     email: data.email,
                     username: data.username,
                     description: data.description,
                       participation: data.participation,
                       twitterUrl: data.twitterUrl,
                       pixivUrl: data.pixivUrl,
                       otherUrl: data.otherUrl
                 }))
   
                 //認証後に移動
                 dispatch(push('/home'))
             })
         }
        })
    }
   }

// signUp関数
// validation thuck型
export const signUp = ({username, email, password, confirmPassword, images, description, participation, twitterUrl, pixivUrl, otherUrl}) => {
    return async (dispatch) => {
     if (username === "" || email === "" || password === "" || confirmPassword === "") {
         alert("必須項目が見入力です")
         return false
     }

     if (password !== confirmPassword) {
         alert("パスワードが一致しません")
         return false
     }

    //  auth の設定
    return auth.createUserWithEmailAndPassword(email,password)
      .then(result => {
          const user = result.user
          
        //   ユーザーが存在していたら
          if(user) {
             const uid = user.uid
             const timestamp = FirebaseTimestamp.now()

             const userInitialData = {
                 created_at: timestamp,
                 email: email,
                 role: "customer",
                 uid: uid,
                 updated_at:  timestamp,
                 username: username,
                 images: images,
                 description: description,
                 participation : participation,
                 twitterUrl : twitterUrl,
                 pixivUrl : pixivUrl,
                 otherUrl: otherUrl,
             }
             db.collection('users').doc(uid).set(userInitialData)
          .then(() => {
              dispatch(push('/signin'))
          })
          }
          
      })
    }
}

// ------------------------
// SignOut関数
// ReduxのStateを初期化しておくこと!!
// auth.signOut() を呼び出して使う(受け取るものはない)
// ------------------------
export const signOut = () => {
    return async (dispatch) => {
        auth.signOut()
        .then(()=> {
            // ReduxのStoreのログイン情報を変更
            dispatch(signOutAction());
            // signInに移動
            dispatch(push('/signin'))
        })
    }
}


// ------------------------
// Reset Password 関数
// onAuthStateChanged (戻り値としてullを返したりする)
// ------------------------
export const ResetPassword = (email) => {
    return async (dispatch) => {
        if(email === "") {
            alert("必須項目が見入力です")
            return false
        } else {
            auth.sendPasswordResetEmail(email)
            .then(() => {
                dispatch(push('/sendEmail'))
            }).catch(() => {
                alert('失敗しました')
            })
        }
    }
}

    // -------------------------------------
    //  addProductToCart 関数
    // ProductEdit(AddProduct関数) の 関数として使う
    // -------------------------------------







    // export const saveImages = ({title,images,description,all,r18,r18g,none,guragiga,gigagura}) => {
    //     return async (dispatch,getState) => {
    //         const uid = getState().users.uid;
    //         const imagesRef = usersRef.doc(uid).collection('images').doc();
    //        //  Validation
    //         // if (title === "" || images === "" || description=== "") {
    //         //    alert("必須項目が見入力です")
    //         //    return false
    //         // }

    //         const timestamp = FirebaseTimestamp.now()

    //   const data = {
    //     title: title,
    //     description: description,
    //     images: images,
    //     all: all,
    //     r18: r18,
    //     r18g: r18g,
    //     none: none,
    //     guragiga: guragiga,
    //     updated_at: timestamp,
    //     gigagura: gigagura
    //   }

    //   return imagesRef.set(data)

    //   .then(()=>{
    //       dispatch(push('/'))
    //   }).catch((error) => {
    //       throw new Error(error)
    //   })
       
        //    //  signInWithEmailAndPassword で ログイン処理を設定する
        //     return auth.signInWithEmailAndPassword(email,password)
        //     .then(result => {
        //        //  ユーザー情報
        //      const user = result.user;
       
        //    //   もしもユーザーが存在したら
        //      if(user) {
        //          const uid = user.uid
        //          db.collection('users').doc(uid).get()
        //          .then(snapshot => {
        //              const data = snapshot.data()
       
        //            //   siginInaction を呼び出して以下の情報に変更する
        //              dispatch(signInAction({
        //                  isSignedin: true,
        //                  role: data.role,
        //                  uid: uid,
        //                  email: data.email,
        //                  username: data.username,
        //                  description: data.description,
        //                    participation: data.participation,
        //                    twitterUrl: data.twitterUrl,
        //                    pixivUrl: data.pixivUrl,
        //                    otherUrl: data.otherUrl
        //              }))
       
        //              //認証後に移動
        //              dispatch(push('/user'))
        //          })
        //      }
        //     })
    //     }
    //    }




    //        // -------------------------------------
    // //  addProductToCart 関数
    // // ProductEdit(AddProduct関数) の 関数として使う
    // // -------------------------------------
    // export const saveImages = ({title,images,description,all,r18,r18g,none,guragiga,gigagura}) => {
    //     return async (dispatch,getState) => {
    //         const uid = getState().users.uid;
    //         // 新しくuserコレクションにさぶコレクションとしてCartを作ることができる
    //         const imagesRef = db.collection('users').doc(uid).collection('images').doc();

    //         const data = {
    //             title: title,
    //             images: images,
    //             description: description,
    //             all: all,
    //             r18: r18,
    //             r18g: r18g,
    //             none: none,
    //             guragiga: guragiga,
    //             gigagura: gigagura
    //         }
    //         db.collection('users').doc(uid).collection('images').doc().set(data)
    //         // 今回追加するデータの中にサブコレクションのidをフィールドとして持たせることができる。
    //         // addedProduct['imagesId'] = imagesRef.id;
    //         // await cartRef.set(addedProduct);
    //         // 本来ならば、Cartページに移動したい
    //         dispatch(push('/'))
    //     }
    // };


    export const fetchUsers = () => { // 5
        return async (dispatch) => { // 6
            usersRef.orderBy('updated_at', 'desc').get() // 7
           .then(snapshots => { // 8
              const usersList = [] // 9
              snapshots.forEach(snapshot => { // 10
                 const user = snapshot.data(); // 11
                 usersList.push(user) // 12
                 console.log(user)
              })
              dispatch(fetchUsersAction(usersList)) // 13
              console.log(usersList)
           })
        }
    };