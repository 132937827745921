import React from 'react';
import styled from 'styled-components';

const ListItem = styled.div`
 border-bottom: solid #ddd 1px;
 display: flex;
 align-items: center;
 padding: 0.5em 0;
 small {
     font-weight:bold;
 }
 strong {
    padding-right: 1em;
    font-size: 0.4em;
 }
`;

const UpDateList = (props) => {

 return(
   <ListItem>
     <p><strong>{props.data}</strong></p>
     <p><small>{props.text}</small></p>
   </ListItem>
 )
}

export default UpDateList;