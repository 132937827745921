import React,{useState,useCallback,useEffect} from 'react';
import {useDispatch} from "react-redux";
import styled from 'styled-components';
// import {UserMenus} from '../components/User';
import {saveNotification} from '../reducks/Notification/operations';
import { db } from '../firebase';

import {SignInput,SelectInput,TextArea} from '../components/UIkit';

const Container = styled.div`
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  margin: 0 auto;
  max-width: 1080px;
`;

const Button = styled.button`
    width: 12em;
    background-color: #FF385C;
    border-radius: 0.5em;
    color: #fff;
    padding: 1em 3em;
    width: 20em;
    font-size: 1em;
    transition: all ease-out .3s;
    cursor: pointer;
    :hover{
        transition: all ease-out .3s;
    background-color: #c63751;
    }
`;

const Main = styled.main`
  width: calc(100% - 300px);
`;

const CenterSpacer = styled.div`
 margin: 0 1em;
`;

const Sidebar = styled.aside`
  width: 300px;
`;

const Title= styled.h3`
 font-size: 1.5em;
 font-weight: bold;
`;

const Top= styled.div`
  margin-top: 10em;
`;

const NotificationForm = () => {
  const dispatch = useDispatch();

    // ----------------------------------
    // DBデータ 出力 (URLからidを取得する)
    // ----------------------------------
    let id = window.location.pathname.split('/notification/form') [1];
    console.log("Before split /", id)
    
    if(id !== "") {
        id = id.split('/')[1]
        console.log("After split /",id);
    }
    

  const [type, setType] = useState(""),
        [subject, setSubject] = useState(""),
        [text, setText] = useState("")

  const types =  [
    {id: "select1", name: '選択してください'},
    {id: "service", name: 'お知らせ'},
    {id: "other", name: '重要な報告'},
];

const inputSubject = useCallback((event) => {
  setSubject(event.target.value)
},[setSubject]);

const inputText = useCallback((event) => {
  setText(event.target.value)
},[setText]);

        useEffect(() => {
          if (id !== "") {
            db.collection('contacts').doc(id).get()
              .then(snapshot => {
                  const data = snapshot.data();
                  setType(data.type);
                  setSubject(data.subject);
                  setText(data.text);
              })
          }
      }, [id])
      
 return(
<Top>
<div className="common__item">
<div className="common__center">
    <Container>
      <Main>
      <Title>お知らせの作成</Title>

      <SelectInput
     name={"type"}
     id={"type"}
     label={"お知らせの種類"}
     options={types}
     select={setType}
     value={type}
     />

      <SignInput
      label={"件名"}
      placeholder={"入力してください"}
      type={"text"}
      required={true}
      name={"subject"}
      id={"subject"}
      className={"subject"}
      autocomplete={"off"}
      value={subject}
      onChange={inputSubject}
     />  

<TextArea onChange={inputText} value={text} />
      </Main>
    </Container>

<Button
onClick={() => dispatch(saveNotification({
    id: id,
    type: type,
    subject: subject,
    text: text}))}
>
         作品を投稿する
        </Button>

</div>
</div>
</Top>

 )
}

export default NotificationForm;