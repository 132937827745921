import React,{useState,useCallback,useEffect} from 'react';
import {useDispatch} from "react-redux";
import {SignInput,SelectInput,ImageArea,TextArea,PrimaryButton} from '../components/UIkit';
import {db} from "../firebase/index";
import {userEdit} from '../reducks/users/operations';
import styled from 'styled-components';

const Text = styled.p`
 margin: 2em 0;
`;

const Button = styled.div`
    margin: 0 auto;
    padding: 1.1em 3em;
    background-color: #FF385C;
    transition: all ease-out .3s;
    color: #fff;
    border-radius:2em;
    border: none;
    width:100%;
    max-width: 15em;
    margin-bottom:1em; 
    :hover{
    padding: 1.1em 3em;
    background: #c63751;
    color: #fff;
    transition: all ease-out .3s;
    border-radius:2em;
    }
`;

const UserEdit = () => {
    const dispatch = useDispatch();
    // ----------------------------------
    // DBデータ 出力 (URLからidを取得する)
    // ----------------------------------
    let id = window.location.pathname.split('/user/edit') [1];
    console.log("Before split /", id)
    
    if(id !== "") {

        id = id.split('/')[1]
        console.log("After split /",id);
    }

    const [username, setUserName] = useState(""),
          [email, setEmail] = useState(""),
          [images, setImages] = useState([]),
          [description, setDescription] = useState(""),
          [participation, setParticipation] = useState(""),
          [twitterUrl, setTwitterUrl] = useState(""),
          [pixivUrl, setPixivUrl] = useState(""),
          [otherUrl, setOtherUrl] = useState(""); 

    const inputUserName = useCallback((event) => {
        setUserName(event.target.value)
    },[setUserName]);

    const inputEmail = useCallback((event) => {
        setEmail(event.target.value)
    },[setEmail]);

    const inputDescription = useCallback((event) => {
        setDescription(event.target.value)
    },[setDescription]);

    const participations =  [
        {id: "select", name: '選択してください'},
        {id: "イラスト", name: 'イラスト'},
        {id: "漫画", name: '漫画'},
        {id: "小説", name: '小説'},
        {id: "その他", name: 'その他'}
    ]; 

    const inputTwitterUrl = useCallback((event) => {
        setTwitterUrl(event.target.value)
    },[setTwitterUrl]);

    const inputPixivUrl = useCallback((event) => {
        setPixivUrl(event.target.value)
    },[setPixivUrl]);

    const inputOtherUrl = useCallback((event) => {
        setOtherUrl(event.target.value)
    },[setOtherUrl]);

  useEffect(()=> {
    if (id !== "") {
      db.collection('users').doc(id).get()
      .then(snapshot => {
        const data = snapshot.data();
        console.log(data);
        setUserName(data.username);
        setEmail(data.email);
        setImages(data.images);
        setDescription(data.description);
        setParticipation(data.participation);
        setTwitterUrl(data.twitterUrl);
        setPixivUrl(data.pixivUrl);
        setOtherUrl(data.otherUrl);
      })
    }
  }, [id]);

 return(
    <div className="common__sub-contents back-gray">
    <div className="common__size">
     <h2>ユーザー情報編集・登録</h2>
     <Text>以下からは、未入力でも登録できます。また、登録後に編集ができます。<br />画像は <strong>正方形</strong> でアップロードしてください。<br />また画像を Click して削除できます。</Text>

    <ImageArea images={images} setImages={setImages} />

     <SignInput
      label={"ユーザー名"}
      placeholder={"入力してください"}
      type={"text"}
      required={true}
      name={"username"}
      id={"username"}
      className={"username"}
      autocomplete={"off"}
      value={username}
      onChange={inputUserName}
     />

<SignInput
      label={"メールアドレス"}
      placeholder={"入力してください"}
      type={"email"}
      required={true}
      name={"email"}
      id={"email"}
      className={"email"}
      autocomplete={"off"}
      value={email}
      onChange={inputEmail}
     />
<TextArea
  label={"自己紹介"}
  placeholder={"入力してください"}
  type={"text"}
  required={true}
  name={"description"}
  id={"description"}
  className={"description"}
  autocomplete={"off"}
  value={description}
  onChange={inputDescription}
/>     

<SelectInput 
     name={"participation"}
     id={"participation"}
     label={"参加形態"}
     options={participations}
     select={setParticipation}
     value={participation}
/>

<SignInput
  label={"TwitterのURL"}
  placeholder={"URLを入力してください"}
  type={"twitterUrl"}
  required={true}
  name={"twitterUrl"}
  id={"twitterUrl"}
  className={"twitterUrl"}
  autocomplete={"off"}
  value={twitterUrl}
  onChange={inputTwitterUrl}
/>   

<SignInput
  label={"pixivのURL"}
  placeholder={"URLを入力してください"}
  type={"pixivUrl"}
  required={true}
  name={"pixivUrl"}
  id={"pixivUrl"}
  className={"twitterUrl"}
  autocomplete={"off"}
  value={pixivUrl}
  onChange={inputPixivUrl}
/> 

<SignInput
  label={"その他のURL"}
  placeholder={"URLを入力してください"}
  type={"otherUrl"}
  required={true}
  name={"vUrl"}
  id={"otherUrl"}
  className={"otherUrl"}
  autocomplete={"off"}
  value={otherUrl}
  onChange={inputOtherUrl}
/> 

<PrimaryButton
label={"編集する"}
onClick={() => dispatch(userEdit(
{
  id: id,
  username: username,
  email: email,
  description: description,
  participation : participation,
  twitterUrl : twitterUrl,
  pixivUrl : pixivUrl,
  otherUrl: otherUrl,
  images: images
}
  ))} />
    </div>
    </div>
 )
}

export default UserEdit;