import React,{useState} from 'react';
import styled,{css} from 'styled-components';

const DropBoxArea = styled.div`
 input {
     position:absolute;
     height: 1px;
     width: 1px;
     overflow: hidden;
     clip: rect(1px, 1px, 1px, 1px);
     opacity: 0;
 }
 label{
     display: flex;
    width: 300px;
 height: 200px;
 border: #ccc 2px dotted;
 margin: 20px;
 align-items: center;
 justify-content: center;
 cursor: pointer;
 ${({dragEnter}) => dragEnter && css`
 border-color: #666;
 `}
 }
`;

const DropBox = ({onFiles}) => {
    const [dragEnter, setDragEnter] = useState(false);

    const handleFile = (e) => {
        console.log(e.target.files);
    }

    const handleDragEnter = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setDragEnter(true);
    }

    const handleDragOver = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setDragEnter(true);
    }

    const handleDragLeave = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setDragEnter(false);
    }

    const handleDrop = (e) => {
        e.stopPropagation();
        e.preventDefault();
        onFiles(e.dataTransfer.files);
    }


 return(
    <>
    <DropBoxArea>
        <label 
        dragEnter={dragEnter} 
        htmlFor="images-upload"
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        >Drop image here</label>

        <input
        onChange={handleFile}
        multiple
         type="file" 
         name="images-upload"
          id="images-upload"/>
    </DropBoxArea>
    </>
 )
}

export default DropBox;
