import React,{useState,useCallback,useEffect} from 'react';
import {useDispatch,useSelector} from "react-redux";
import styled from 'styled-components';
import {saveNotification} from '../reducks/Novel/operations';
import { getUsername } from '../reducks/users/selectors';
import {SelectInput,SignInput,ImageArea,PrimaryButton,TextArea} from '../components/UIkit';

const ButtonArea= styled.div`
 margin-top: 3em;
`;

const Text= styled.p`
text-align:center;
padding-top:1.5em;
`;

const Title= styled.h3`
 font-size: 1.5em;
 font-weight: bold;
`;

const Top= styled.div`
  margin-top: 10em;
`;

const ImageNovel = () => {
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  const username = getUsername(selector);

  const [title, setTitle] = useState(""),
        [description, setDescription] = useState(""),
        [images, setImages] = useState(""),
        [name, setName] = useState(""),
        [old, setOld] = useState(""),
        [main, setMain] = useState(""),
        [coupling, setCoupling] = useState("");


        const inputName = useCallback((event) => {
          setName(event.target.value)
        },[setName]);

const inputTitle = useCallback((event) => {
  setTitle(event.target.value)
},[setTitle]);

const inputMain= useCallback((event) => {
  setMain(event.target.value)
},[setMain]);

const inputDescription = useCallback((event) => {
  setDescription(event.target.value)
},[setDescription]);

const olds =  [
  {id: "select1", name: '選択してください'},
  {id: "年齢指定なし", name: '年齢指定なし'},
  {id: "R18", name: 'R18'},
  {id: "R18G", name: 'R18G'}
];

const couplings =  [
  {id: "select2", name: '選択してください'},
  {id: "カップリングなし", name: 'カップリングなし'},
  {id: "guraギガ", name: 'Guraギガ'},
  {id: "ギガGura", name: 'ギガGura'}
];
   
 return(

<Top>
<div className="common__contents">
    <div className="common__size">

      <Title>小説を投稿する</Title>
      <Text>表紙の画像を投稿することができます。</Text>

<SignInput
      label={"作品タイトル"}
      placeholder={"記入してください"}
      type={"text"}
      required={true}
      name={"title"}
      id={"title"}
      className={"title"}
      autocomplete={"off"}
      value={title}
      onChange={inputTitle}
     />

<TextArea
      label={"本文"}
      placeholder={"入力してください"}
      type={"text"}
      required={true}
      name={"main"}
      id={"main"}
      className={"main"}
      autocomplete={"off"}
      value={main}
      onChange={inputMain}
     />  

      <TextArea
      label={"作品詳細"}
      placeholder={"入力してください"}
      type={"text"}
      required={true}
      name={"description"}
      id={"description"}
      className={"description"}
      autocomplete={"off"}
      value={description}
      onChange={inputDescription}
     />  

<ImageArea images={images} setImages={setImages} />

<SignInput
      label={"投稿者名"}
      placeholder={"入力してください"}
      type={"text"}
      required={true}
      name={"name"}
      id={"name"}
      className={"name"}
      autocomplete={"off"}
      value={username}
      onChange={inputName}
     />  

<SelectInput
     name={"old"}
     id={"old"}
     label={"年齢制限"}
     options={olds}
     select={setOld}
     value={old}
     />

<SelectInput
     name={"coupling"}
     id={"coupling"}
     label={"カップリング"}
     options={couplings}
     select={setCoupling}
     value={coupling}
     />
    
    <ButtonArea>
<PrimaryButton
label={"作品を投稿する"}
onClick={() => dispatch(saveNotification({
    description: description,
    old: old,
    main:main,
    coupling: coupling,
    title:title,
    images:images,
    name:name
    }))}
/>

   </ButtonArea>
</div>
</div>
</Top>
 )
}

export default ImageNovel;