const initialState = {
   notifications: {
      list: [],
     },
   images: {
      list: []
     },
     novels: {
      list: []
     },  
     users: {
        isSignedIn: false,
        uid: "",
        role: "",
        username: "",
        email: "",
        description: "",
        participation: "",
        twitterUrl: "",
        pixivUrl: "",
        otherUrl: "",
        list: [] // 3
     }
};

export default initialState