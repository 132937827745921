import React,{useState} from 'react';
import '../../assets/style/slider.css';
import SliderData from './SliderData';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight,faAngleLeft } from "@fortawesome/free-solid-svg-icons";

const ImageSlider = ({ slides }) => {
    const [current, setCurrent] = useState(0);
    const length = slides.length;

    const nextSlide = () => {
        // imageの長さ分クリックしたら reset 0 へ戻る
        setCurrent(current === length -1 ? 0 : current + 1)
    }

    const prevSlide = () => {
        // imageの長さ分クリックしたら reset 0 へ戻る
        setCurrent(current === 0 ? length - 1 : current - 1)
    }

    console.log(current);

    // Slide がなかったら
    if(!Array.isArray(slides) || slides.length <= 0) {
        return null
    }

 return(
    <section className="slider">
        <FontAwesomeIcon className="left-arrow" icon={faAngleLeft} onClick={prevSlide} />
        <FontAwesomeIcon className="right-arrow" icon={faAngleRight} onClick={nextSlide} />
     {SliderData.map((slide, index) => {
         return (
             <div className={index === current ? 'slide active'  : 'slide'} key={index}>
                 {index === current && (
                     <img src={slide.image} alt="image" className="slider__image" />
                 )}
                 
             </div>
         )
     })}
    </section>
   );
   };

export default ImageSlider;