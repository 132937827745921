import React from 'react';
import {useDispatch} from "react-redux";
import styled from 'styled-components';
// import {PrimaryButton} from '../components/UIkit';
import {push} from 'connected-react-router';

const TextArea = styled.div`
padding: 2em 0;
        p {
            text-align: center;
        }
`;

const SendMessage = () => {
 const dispatch = useDispatch();
 return(
    <div className="common__sub-contents">
    <div className="common__size">
      <h2>メールをお送りしました。</h2>
      <TextArea>
      <p>入力いただきましたメールアドレスに <strong>新規登録</strong> または <strong>パスワードリセット</strong> のメールをお送りしました。</p>
      <p>メールの内容に従い、処理を行ってください。</p>
      </TextArea>

      {/* <PrimaryButton label={"ホームに戻る"} 
                      onClick= {() => dispatch(push('/'))}
      /> */}
     
</div>
</div>
 )
}

export default SendMessage;