import React from 'react';
import styled from 'styled-components';

const ImageArea = styled.div`
img {
    width: 18em;
    border-radius: 1em;
}
`;

const ImagePreview = (props) => {
  
 return(
  <ImageArea onClick={() => props.delete(props.id)}>
      <img src={props.path} alt="商品画像"/>
  </ImageArea>
 )
}

export default ImagePreview;