import React,{useCallback,useState} from 'react';
import {useDispatch} from "react-redux";
import styled from 'styled-components';
import {SignInput,ImageArea,SelectInput,TextArea,PrimaryButton,Modal} from '../components/UIkit';
import {push} from 'connected-react-router';
import {signUp} from '../reducks/users/operations';

const Form = styled.div`
    p {
        padding: 0.8em 0;
    }
    h3 {
        padding-top: 3em;
        color: #FF385C;
        font-weight: bold;
    }
`;

const Texts = styled.div`
        text-align: center;
        padding-top: 2em;
        p{
            margin: 0 auto;
            color: #FF385C;
        }
`;

const Text = styled.p`
 margin: 2em 0;
`;

const Overlay= styled.div`
 background-color: rgba(255, 136, 186, 0.692);
 height: 100%;
 position: fixed;
 transition:  all 1.3s;
 width: 100%;
`;

const SignUp = () => {
    const dispatch = useDispatch();
    const [email, setEmail] = useState(""),
          [username, setUsername] = useState(""),
          [password, setPassword] = useState(""),
          [images, setImages] = useState(""),
          [confirmPassword, setConfirmPassword] = useState(""),
          [description, setDescription] = useState(""),
          [participation, setParticipation] = useState(""),
          [twitterUrl, setTwitterUrl] = useState(""),
          [pixivUrl, setPixivUrl] = useState(""),
          [otherUrl, setOtherUrl] = useState(""); 

    const inputEmail = useCallback((event) => {
        setEmail(event.target.value)
    },[setEmail]);
    const inputUsername = useCallback((event) => {
        setUsername(event.target.value)
    },[setUsername]);
    const inputPassword = useCallback((event) => {
        setPassword(event.target.value)
    },[setPassword]);
    const inputConfirmPassword = useCallback((event) => {
        setConfirmPassword(event.target.value)
    },[setConfirmPassword]);
    const inputDescription = useCallback((event) => {
        setDescription(event.target.value)
    },[setDescription]);
    const participations =  [
        {id: "select", name: '選択してください'},
        {id: "イラスト", name: 'イラスト'},
        {id: "漫画", name: '漫画'},
        {id: "小説", name: '小説'},
        {id: "その他", name: 'その他'}
    ]; 

    const inputTwitterUrl = useCallback((event) => {
        setTwitterUrl(event.target.value)
    },[setTwitterUrl]);

    const inputPixivUrl = useCallback((event) => {
        setPixivUrl(event.target.value)
    },[setPixivUrl]);

    const inputOtherUrl = useCallback((event) => {
        setOtherUrl(event.target.value)
    },[setOtherUrl]);


     const [show,setShow] = useState(false);
     const closeModalHandler = () => setShow(false);
    
 return(
    <div className="common__sub-contents">
    <div className="common__size">
    { show ? <Overlay onClick={closeModalHandler} ></Overlay> : null }
<Form>
<div className="common__center back__gray">
      <h2>新規登録</h2>
      <h3>基本情報</h3>
     <SignInput
      placeholder={"メールアドレス"}
      label={"メールアドレス"}
      type={"email"}
      name={"email"}
      id={"email"}
      className={"email"}
      autocomplete={"off"}
      value={email}
      onChange={inputEmail}
     />

<SignInput
      placeholder={"ユーザー名"}
      label={"ユーザー名"}
      type={"text"}
      name={"text"}
      id={"text"}
      className={"name"}
      autocomplete={"off"}
      value={username}
      onChange={inputUsername}
     />

<SignInput
      placeholder={"パスワード"}
      label={"パスワード(英数字の6文字以上)"}
      type={"password"}
      name={"password"}
      id={"password"}
      className={"password"}
      autocomplete={"off"}
      value={password}
      onChange={inputPassword}
     />

<SignInput
      placeholder={"パスワード(確認)"}
      label={"パスワード(確認)"}
      type={"password"}
      name={"password"}
      id={"password"}
      className={"password"}
      autocomplete={"off"}
      value={confirmPassword}
      onChange={inputConfirmPassword}
     />

<h3>詳細情報</h3>
<Text>以下からは、未入力でも登録できます。また、登録後に編集ができます。<br />画像は <strong>正方形</strong> でアップロードしてください。画像は Click をすると削除できます。</Text>

<ImageArea images={images} setImages={setImages} />

<TextArea
  label={"自己紹介"}
  placeholder={"入力してください"}
  type={"text"}
  required={true}
  name={"description"}
  id={"description"}
  className={"description"}
  autocomplete={"off"}
  value={description}
  onChange={inputDescription}
/>  

<SelectInput 
     name={"participation"}
     id={"participation"}
     label={"参加形態"}
     options={participations}
     select={setParticipation}
     value={participation}
/>

<SignInput
  label={"TwitterのURL"}
  placeholder={"URLを入力してください"}
  type={"twitterUrl"}
  required={true}
  name={"twitterUrl"}
  id={"twitterUrl"}
  className={"twitterUrl"}
  autocomplete={"off"}
  value={twitterUrl}
  onChange={inputTwitterUrl}
/>   

<SignInput
  label={"pixivのURL"}
  placeholder={"URLを入力してください"}
  type={"pixivUrl"}
  required={true}
  name={"pixivUrl"}
  id={"pixivUrl"}
  className={"twitterUrl"}
  autocomplete={"off"}
  value={pixivUrl}
  onChange={inputPixivUrl}
/> 

<SignInput
  label={"その他のURL"}
  placeholder={"URLを入力してください"}
  type={"otherUrl"}
  required={true}
  name={"vUrl"}
  id={"otherUrl"}
  className={"otherUrl"}
  autocomplete={"off"}
  value={otherUrl}
  onChange={inputOtherUrl}
/> 

<PrimaryButton
label={"登録する"}
onClick={() => 
 {
    dispatch(signUp({
        username: username,
        email: email,
        password: password,
        images: images,
        description: description,
        participation:participation,
        twitterUrl: twitterUrl,
        pixivUrl: pixivUrl,
        otherUrl: otherUrl,
        confirmPassword: confirmPassword
        }));
        setShow(true);
 }
}
/>
<Modal show={show} close={closeModalHandler} />

<Texts>
<p onClick={() => dispatch(push('/signin'))}>アカウントをお持ちの方はこちら</p>
<p onClick={() => dispatch(push('/user/reset'))}>パスワードをお忘れの方はこちら</p>
</Texts>

     </div>
</Form>
</div>
</div>
 )
}

export default SignUp;