import React from 'react'; // 1
import styled from 'styled-components';

const Item = styled.div`
 background-color: red;
 padding: 2em 0;
 margin: 0 1em;
 background-color: #fff;
 box-shadow: 0px 3px 15px rgb(0 0 0 / 20%);
 border-radius: 1em;
 transition: all ease-out .3s;
 width: 100%;
 max-width: 20em;
 text-align: center;
 :hover {
  background-color: #f9f9f9;
  transition: all ease-out .3s;

 }
  p{
      margin: 0 auto;
      color: #3b3b3b;
  }
`;

const ItemContents = styled.div`
 padding: 1em;
`;

const PostCard = (props) => {
return(
 <Item>
  <p>textです</p>
 </Item>
 )
}

export default PostCard;